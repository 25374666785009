import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import { Box } from '@material-ui/core';
import useScreenSize from '../../../hooks/useScreenSize';
import { findRel } from '../../../shared/utils/stringUtilities';
import { findTarget } from '../../../utils/findTarget';
import { generateSlug } from '../../../utils/generateSlug';
import styles from './topbarMenu.module.scss';
import './topbarMenu.scss';

const TopbarMenu = () => {
    const screenSize = useScreenSize();
    const [topbarData, setTopbarData] = useState<any>();
    useEffect(() => {
        const _get = fetch('/components/topbar-info.json');
        _get.then(async (resp) => {
            const topbarInfo = await resp.json();
            setTopbarData(topbarInfo[0]);
        });
    }, []);
    const settings = {
        dots: true,
        infinite: false,
        slidesToShow: screenSize / 326,
        slidesToScroll: 1,
        autoplay: false,
    };

    return (
        <>
            {topbarData &&
                (screenSize > 968 ? (
                    <Box className={styles.m_topbarm} component="nav">
                        {topbarData.enlaces.map((enlace, index) => (
                            <a
                                id={`gtm_click_menu_comfama_home_body_servicios_${generateSlug(
                                    enlace.label,
                                )}`}
                                key={index}
                                href={enlace.link}
                                target={findTarget(enlace.link)}
                                rel={findRel(enlace.link)}
                                className={styles.m_topbarm__link}
                            >
                                {enlace.label}
                            </a>
                        ))}
                    </Box>
                ) : (
                    <Slider {...settings} className="m_topbarm__mobile">
                        {topbarData.enlaces.map((enlace, index) => (
                            <a
                                id={`gtm_click_menu_comfama_home_body_servicios_${generateSlug(
                                    enlace.label,
                                )}`}
                                key={index}
                                href={enlace.link}
                                target={findTarget(enlace.link)}
                                rel={findRel(enlace.link)}
                                className={styles.m_topbarm__card}
                            >
                                <div className={styles.m_topbarm__content}>
                                    <i className={enlace.icon} />
                                    <h3>{enlace.label}</h3>
                                    {enlace.desc && <p>{enlace.desc}</p>}
                                </div>
                            </a>
                        ))}
                    </Slider>
                ))}
        </>
    );
};

export default TopbarMenu;
