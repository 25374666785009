import React, { useState } from 'react';
import {
    AppBar,
    Typography,
    Container,
    Grid,
    Drawer,
    Paper,
} from '@material-ui/core';
import ZonaTransaccional from './../../molecules/zona-transaccional/zonaTransaccional';
import Searcher from '../../molecules/searcher/searcher';
import Nav from '../nav/nav';
import logoMagenta from '../../../assets/images/logos/comfama/logo-magenta.svg';
import styles from './header.module.scss';
import './header.scss';

function Header(props: any) {
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [searcherActive, setSearcherActive] = useState(false);

    const handleSearcher = () => {
        setSearcherActive(!searcherActive);
    };

    return (
        <>
            <AppBar
                position="fixed"
                className={`${styles.o_header} ${
                    drawerOpen ? styles.menu_open : styles.menu_close
                }`}
            >
                <Container className={styles.o_header__container}>
                    <Grid
                        container
                        lg={12}
                        className={styles.o_header__toolbar}
                    >
                        <Typography
                            component="div"
                            className={styles.o_header__logo}
                        >
                            <a
                                href="/"
                                aria-label="Página principal Comfama"
                                target="_self"
                                rel="referrer follow"
                            >
                                <img src={logoMagenta} alt="Logo Comfama" />
                            </a>
                        </Typography>
                        <div className={styles.o_header__items}>
                            <div className={styles.o_header__options}>
                                <ZonaTransaccional />
                                <a
                                    href="https://www.comfama.com/ayuda"
                                    aria-label="centro de ayuda"
                                    target="_black"
                                    rel="referrer follow"
                                    className={`gtm_click_button_comfama_home_header_ayuda ${styles.o_header__help}`}
                                >
                                    Ayuda
                                    <i
                                        className="icon-question-circle1"
                                        style={{ pointerEvents: 'none' }}
                                    />
                                </a>
                                <button
                                    type="button"
                                    aria-label="Buscar en comfama.com"
                                    onClick={() => handleSearcher()}
                                    className={`gtm_click_button_comfama_home_header_buscador ${styles.o_header__search}`}
                                >
                                    <span>Buscador</span>
                                    <i className="icon icon-search" />
                                </button>
                                <Drawer
                                    open={searcherActive}
                                    onClose={() =>
                                        setSearcherActive(!searcherActive)
                                    }
                                    anchor="right"
                                    className={styles.o_header__searcher}
                                    transitionDuration={300}
                                >
                                    <Paper
                                        style={{
                                            width: '100vw',
                                            height: '100vh',
                                        }}
                                    >
                                        <button
                                            className={styles.o_header__close}
                                            onClick={() => handleSearcher()}
                                        >
                                            <i className="icon-close" />
                                        </button>
                                        <Searcher type="comfama" />
                                    </Paper>
                                </Drawer>
                            </div>
                            <div className={styles.o_header__burger}>
                                <button
                                    aria-label="abrir menú"
                                    onClick={() => setDrawerOpen(!drawerOpen)}
                                >
                                    Menú
                                    {drawerOpen ? (
                                        <i className="icon-close" />
                                    ) : (
                                        <i className="icon-menu-hamburger" />
                                    )}
                                </button>
                            </div>
                        </div>
                    </Grid>
                </Container>
            </AppBar>
            <Drawer
                className={`${styles.o_header__drawer} o_header__drawer`}
                open={drawerOpen}
                onClose={() => setDrawerOpen(!drawerOpen)}
                anchor="right"
                transitionDuration={300}
            >
                <Nav
                    isMenuActive={drawerOpen}
                    isSearcherActive={searcherActive}
                    isDark={false}
                    actionTheme={() => console.log}
                    menuType={props.menuEspecifico}
                    hideSidebar={true}
                />
            </Drawer>
        </>
    );
}

export default Header;
