import React, { useState, useRef, useEffect } from 'react';
import outsideClick from '../../../utils/outsideClick';
import styles from './zonaTransaccional.module.scss';

const Dropdown = () => {
    const [activeZT, setActiveZT] = useState(false);
    const ztItemRef = useRef(null);
    const ztContainerRef = useRef(null);

    const handleClickZT = () => {
        if (activeZT) {
            setActiveZT(false);
        } else {
            setActiveZT(true);
        }
    };

    useEffect(() => {
        if (!activeZT) {
            ztItemRef.current?.querySelectorAll('a').forEach((link) => {
                link.setAttribute('tabindex', '-1');
            });
        } else {
            ztItemRef.current?.querySelectorAll('a').forEach((link) => {
                link.removeAttribute('tabindex');
            });
        }
    }, [activeZT]);

    const handleOutsideClickZT = () => {
        setActiveZT(false);
    };

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 0) {
                setActiveZT(false);
            }
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div ref={ztContainerRef} className={styles.m_zt}>
            <button onClick={() => handleClickZT()} className='gtm_click_menu_comfama_home_header_transaccional'>
                Zona transaccional
                <i
                    className={`icon-chevron-down1 ${
                        activeZT ? styles.zt__open : ''
                    }`}
                    style={{
                        pointerEvents: 'none',
                    }}
                />
            </button>
            <div
                ref={ztItemRef}
                className={styles.m_ztopen}
                style={{
                    opacity: activeZT ? 1 : 0,
                    top: activeZT ? 49 : 0,
                    pointerEvents: activeZT ? 'initial' : 'none',
                }}
            >
                <a
                    href="https://www.comfama.com/portal-servicios/personas/"
                    target="_black"
                    rel="referrer follow"
                >
                    <i className="icon-users" />
                    Personas
                </a>
                <a
                    href="https://www.comfama.com/portal-servicios/empresas/"
                    target="_black"
                    rel="referrer follow"
                >
                    <i className="icon-industry" />
                    Empresas
                </a>
            </div>
            {outsideClick(ztContainerRef, handleOutsideClickZT)}
        </div>
    );
};

export default Dropdown;
