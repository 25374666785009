import React, { useState, useEffect, useRef, MutableRefObject } from "react";

import useMetaSearch from "../../../shared/hooks/useMetaSearch";
import useBrowserMode from "../../../shared/hooks/useBrowserMode";

import "./searcher.scss";
import { useStaticQuery, graphql } from "gatsby";
import { normalizeTwoWords } from "../../../shared/utils/stringUtilities";

const Searcher = (props: SearcherInterface) => {
   const data = useStaticQuery(graphql`
      query SEARCHER_PLACEHOLDER {
         allContentfulHome(filter: { contentful_id: { eq: "5u6Xd4ijda6BS91FIWos4Y" }}) {
            nodes {
               placeholdersBuscador
            }
         }
      }
   `);

   const placeholders = data.allContentfulHome.nodes[0].placeholdersBuscador;
   const { compact, type, onSelect, scrollIntoQuestion, defaultPlaceholder } = props;
   
   const node: MutableRefObject<any> = useRef();

   const [isActive, setActiveSelect] = useState(false);
   const [dynamicPlaceholder, setDynamicPlaceholder] = useState("");
   const [searchWord, setSearchWord] = useState("");

   const { filterSearch, doSearch } = useMetaSearch(type);
   const { isBrowser, window, document } = useBrowserMode();

   const handleClickOutside = (e: Event) => {
      if (node.current.contains(e.target)) {
         return;
      } else {
         setActiveSelect(false);
      }
   };

   const handleKeyDown = (e) => {
      if (e.key === "Enter" && type === "comfama") {
         isBrowser &&
            searchWord.trim().length > 2 &&
            window.location.assign(
               `/resultados-busqueda/?search=${searchWord}&sort=ASC`
            );
      }
      return;
   };

   useEffect(() => {
      if (type === "comfama" || type === "headboard") {
         let cont = 0;

         const timer = setInterval(() => {
            if (cont > 2) cont = 0;
            setDynamicPlaceholder(`${placeholders[cont]}`);
            cont++;
         }, 1200);

         return () => clearInterval(timer);
      }
   }, []);

   useEffect(() => {
      document.addEventListener("mousedown", handleClickOutside);

      return () => {
         document.removeEventListener("mousedown", handleClickOutside);
      };
   }, [isActive]);

   const renderFormattedText = (text, searchWord) => {
      const comparingText =
         text !== null && text.toLowerCase().indexOf(searchWord.toLowerCase());

      const formattedText =
         text !== null && comparingText !== -1 ? (
            <React.Fragment>
               {text.slice(0, comparingText)}
               <span>
                  {text.slice(comparingText, comparingText + searchWord.length)}
               </span>
               {text.slice(comparingText + searchWord.length)}
            </React.Fragment>
         ) : (
            text
         );

      return formattedText;
   };

   return (
      <div
         className={`m-searcher-container ${
            compact ? "m-searcher-container--compact" : ""
         } ${
            type === "preguntas" ? "m-searcher-container--compact-extended" : ""
         } ${
            type === "headboard" ? "--headboard" : ""
         }`}
      >
         <div
            className={`m-searcher-container m-searcher-container__content ${
               isActive || searchWord.trim().length > 2
                  ? "m-searcher-container__content--active"
                  : ""
            }`}
         >
            <div className="m-searcher-container__input">
               <i
                  className="icon icon-search"
                  onClick={() =>
                     isBrowser &&
                     searchWord.trim().length > 2 &&
                     window.location.assign(
                        `/resultados-busqueda/?search=${searchWord}&sort=ASC`
                     )
                  }
               />
               <input
                  autoFocus
                  ref={node}
                  value={searchWord}
                  placeholder={
                     type === "preguntas"
                        ? "¿Qué estás buscando?"
                        : defaultPlaceholder || dynamicPlaceholder
                  }
                  onClickCapture={() => setActiveSelect(true)}
                  onChange={(text) => {
                     doSearch(text.currentTarget.value);
                     setSearchWord(text.currentTarget.value);
                  }}
                  onKeyDown={handleKeyDown}
               />
               {searchWord.trim().length > 2 && (
                  <i
                     className="icon icon-times-circle1"
                     onClickCapture={() => {
                        setSearchWord("");
                        doSearch("");
                     }}
                  />
               )}
            </div>
            {(searchWord.trim().length > 2 || !compact) && (
               <div className="m-searcher-container__separator" />
            )}
            {(searchWord.trim().length > 2 || !compact) && (
               <div className={`m-searcher-container__results ${searchWord.trim().length > 2 ? '' : '--hide-results'} ${type === "headboard" ? '--headboard-results' : ''}`}>
                  <ul>
                     {compact && type === "preguntas" && filterSearch && filterSearch.length === 0 && (
                        <li>
                           <span className="m-searcher-results__title">
                              {`No encontramos resultados para "${searchWord.trim()}"`}
                           </span>
                        </li>
                     )}
                     {filterSearch && filterSearch.map((result, index) => (
                        <li
                           key={`result${index}`}
                           onClick={() => {
                              if (
                                 onSelect !== undefined &&
                                 scrollIntoQuestion !== undefined
                              ) {
                                 onSelect(result);
                                 setSearchWord("");
                                 doSearch("");
                                 scrollIntoQuestion(result.titulo);
                              }
                           }}
                        >
                           <a
                              href={( type === "comfama" || type === "headboard") ? `${result.slug}` : "#"}
                              target={type === "headboard" ? "_blank" : "_self"}
                              rel="noreferrer"
                              className= {`gtmResultadosPredictivo${normalizeTwoWords(type==="comfama"?result.titulo:result.tituloBanner)}${normalizeTwoWords(result.tipoContenido)}${normalizeTwoWords(result.categoria)}`}
                           >
                              <p className= {`gtmResultadosPredictivo${normalizeTwoWords(type==="comfama"?result.titulo:result.tituloBanner)}${normalizeTwoWords(result.tipoContenido)}${normalizeTwoWords(result.categoria)}`}>
                                 <span className={`m-searcher-results__title 
                                       gtmResultadosPredictivo${normalizeTwoWords(type==="comfama"?result.titulo:result.tituloBanner)}${normalizeTwoWords(result.tipoContenido)}${normalizeTwoWords(result.categoria)}`}>
                                    {renderFormattedText(
                                       ( type === "comfama" || type === "headboard" )
                                          ? result.titulo
                                          : result.tituloBanner,
                                       searchWord
                                    )}
                                 </span>
                                 <br />
                                 <span className={`m-searcher-results__subtitle
                                       gtmResultadosPredictivo${normalizeTwoWords(type==="comfama"?result.titulo:result.tituloBanner)}${normalizeTwoWords(result.tipoContenido)}${normalizeTwoWords(result.categoria)}
                                 `}>
                                    {( type === "comfama" || type === "headboard" ) &&
                                       renderFormattedText(
                                          result.tipoContenido,
                                          searchWord
                                       )}
                                    {( type === "comfama" || type === "headboard" ) && ": "}
                                    {renderFormattedText(
                                       result.categoria,
                                       searchWord
                                    )}
                                 </span>
                              </p>
                           </a>
                        </li>
                     ))}
                  </ul>
               </div>
            )}
         </div>
      </div>
   );
};

interface SearcherInterface {
   compact?: boolean;
   type?: "comfama" | "preguntas" | "headboard";
   onSelect?: Function;
   scrollIntoQuestion?: Function;
   defaultPlaceholder?: string
}

export default Searcher;
