import React, { useState, useEffect } from 'react';
import { normalizeTwoWords } from '../../../shared/utils/stringUtilities';
import useBrowserMode from '../../../shared/hooks/useBrowserMode';
import NavAccordion from '../../atoms/nav-accordion/navAccordion';
import Searcher from '../../molecules/searcher/searcher';
import Follows from '../follows/follows';
import { Box } from '@material-ui/core';
import TopbarMenu from '../topbar-menu/topbarMenu';
import './navComponent.scss';

const NavComponent = (props: NavComponentInterface) => {
    const data = props.data;

    const {
        categorias,
        listaDerecha,
    } = data.allContentfulMenuCategorias.nodes[0];
    const footerCategories = data.allContentfulFooter.nodes[0].categorias;

    const { isMenuActive, isSearcherActive, sideLinks } = props;
    const { document } = useBrowserMode();

    const [activeItem, setActiveItem] = useState(0);
    const [isAnyActive, setIsAnyActive] = useState(true);

    useEffect(() => {
        const menu = document.querySelector('.m-nav-component');
        if (menu) menu.scrollTop = 0;
    }, [isMenuActive === true]);

    return (
        <nav
            className={`m-nav-component ${
                isMenuActive || isSearcherActive ? '' : 'm-nav-component--hide'
            }`}
        >
            {isSearcherActive ? (
                <Searcher type="comfama" />
            ) : (
                <React.Fragment>
                    <Box className="m-nav-component__zt">
                        <a
                            href="https://www.comfama.com/portal-servicios"
                            rel="noreferrer follow"
                            target="_blank"
                        >
                            Zona transaccional
                        </a>
                        <a
                            href="https://www.comfama.com/ayuda"
                            rel="noreferrer follow"
                            target="_blank"
                        >
                            Ayuda
                        </a>
                    </Box>
                    <div className="m-nav-component__accordion">
                        {categorias &&
                            categorias.map((cat, i) => (
                                <NavAccordion
                                    key={`cat${i}`}
                                    index={i}
                                    category={cat}
                                    activeItem={activeItem}
                                    setActiveItem={setActiveItem}
                                    isAnyActive={isAnyActive}
                                    setIsAnyActive={setIsAnyActive}
                                />
                            ))}
                    </div>
                    <div className="m-nav-component__sidelinks">
                        <ul>
                            {sideLinks &&
                                sideLinks.map((item, i) => (
                                    <li key={`link${i}`}>
                                        <a
                                            href={
                                                item.hyperLink
                                                    ? item.hyperLink
                                                    : '/' + item.slug
                                            }
                                            target={
                                                item.hyperLink
                                                    ? '_blank'
                                                    : '_self'
                                            }
                                            rel={
                                                item.hyperLink
                                                    ? 'nofollow noreferrer'
                                                    : 'follow'
                                            }
                                        >
                                            <img
                                                loading="lazy"
                                                src={
                                                    item.imagenDestacada &&
                                                    item.imagenDestacada[0].file
                                                        .url
                                                }
                                                alt={
                                                    item.imagenDestacada[0]
                                                        .description
                                                }
                                            />
                                        </a>
                                    </li>
                                ))}
                        </ul>
                    </div>
                    <div className="m-nav-component__topbar">
                        <span>Accede a:</span>
                        <TopbarMenu />
                    </div>
                    <div
                        className={`m-nav-component__links ${
                            !isAnyActive ? 'm-nav-component__links--locked' : ''
                        }`}
                    >
                        <ul className="m-nav-component__links-list">
                            {listaDerecha &&
                                listaDerecha.map((link, i) => (
                                    <li
                                        key={`cat${i}`}
                                        className={`gtmMenuTransLateral${normalizeTwoWords(
                                            link.nombreCategoria,
                                        )}`}
                                    >
                                        <a
                                            className={`gtmMenuTransLateral${normalizeTwoWords(
                                                link.nombreCategoria,
                                            )}`}
                                            href={
                                                link.hyperLink ||
                                                '/' + link.slug
                                            }
                                            target={
                                                link.hyperLink
                                                    ? '_blank'
                                                    : '_self'
                                            }
                                            rel={
                                                link.hyperLink
                                                    ? 'nofollow noreferrer'
                                                    : 'follow'
                                            }
                                        >
                                            {' '}
                                            {link.nombreCategoria}{' '}
                                        </a>
                                    </li>
                                ))}
                        </ul>
                        <div className="m-nav-component__links-social">
                            <ul>
                                {footerCategories[
                                    footerCategories.length - 1
                                ].nombreCategoria.includes('Síguenos') &&
                                    footerCategories[
                                        footerCategories.length - 1
                                    ].referenciaSubcategorias && (
                                        <Follows
                                            query={
                                                footerCategories[
                                                    footerCategories.length - 1
                                                ]
                                            }
                                        />
                                    )}
                            </ul>
                        </div>
                    </div>
                </React.Fragment>
            )}
        </nav>
    );
};

interface NavComponentInterface {
    isMenuActive: boolean;
    isSearcherActive: boolean;
    sideLinks: any;
    isDark: boolean;
    actionTheme?: () => void;
    data: any;
}

export default NavComponent;
