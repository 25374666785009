import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import NavMenu from '../../molecules/nav-menu/navMenu';
import NavMenuInforme2020 from '../../molecules/nav-menu-informe2020/navMenuInforme2020';
import NavMenuInforme2021 from '../../molecules/nav-menu-informe2021/navMenuInforme2021';
import NavMenuInforme2022 from '../../molecules/nav-menu-informe2022/navMenuInforme2022';
import NavMenuInforme2023 from '../../molecules/nav-menu-informe2023/navMenuInforme2023';
import NavMenuBiosuroeste from '../../molecules/nav-menu-biosuroeste/navMenuBiosuroeste';
import './nav.scss';

interface NavInterface {
    isMenuActive: boolean;
    isSearcherActive: boolean;
    isDark: boolean;
    actionTheme: () => void;
    menuType: string | any;
    hideSidebar?: boolean;
}

const Nav = ({
    isMenuActive,
    isSearcherActive,
    isDark,
    actionTheme,
    menuType,
}: NavInterface) => {
    const data = useStaticQuery(graphql`
        query MENU_SIDE_OPTIONS {
            allContentfulMenuCategorias(
                filter: { titulo: { eq: "Menu comfama.com" } }
            ) {
                nodes {
                    iconosLaterales {
                        slug
                        hyperLink
                        nombreCategoria
                        imagenDestacada {
                            description
                            file {
                                url
                            }
                        }
                    }
                }
            }
        }
    `);

    const switchMenu = (menu: string | any) => {
        switch (menu) {
            case 'Informe 2020':
                return (
                    <NavMenuInforme2020
                        isMenuActive={isMenuActive}
                        isSearcherActive={isSearcherActive}
                        sideLinks={iconosLaterales}
                        isDark={isDark}
                        actionTheme={actionTheme}
                    />
                );
            case 'Informe 2021':
                return (
                    <NavMenuInforme2021
                        isMenuActive={isMenuActive}
                        isSearcherActive={isSearcherActive}
                        sideLinks={iconosLaterales}
                        isDark={isDark}
                        actionTheme={actionTheme}
                    />
                );
            case 'Informe 2022':
                return (
                    <NavMenuInforme2022
                        isMenuActive={isMenuActive}
                        isSearcherActive={isSearcherActive}
                        sideLinks={iconosLaterales}
                        isDark={isDark}
                        actionTheme={actionTheme}
                    />
                );
            case 'Informe 2023':
                return (
                    <NavMenuInforme2023
                        isMenuActive={isMenuActive}
                        isSearcherActive={isSearcherActive}
                        sideLinks={iconosLaterales}
                        isDark={isDark}
                        actionTheme={actionTheme}
                    />
                );
            case 'Biosuroeste':
                return (
                    <NavMenuBiosuroeste
                        isMenuActive={isMenuActive}
                        isSearcherActive={isSearcherActive}
                        sideLinks={iconosLaterales}
                        isDark={isDark}
                        actionTheme={actionTheme}
                    />
                );
            default:
                return (
                    <NavMenu
                        isMenuActive={isMenuActive}
                        isSearcherActive={isSearcherActive}
                        sideLinks={iconosLaterales}
                        isDark={isDark}
                        actionTheme={actionTheme}
                    />
                );
        }
    };

    const { iconosLaterales } = data.allContentfulMenuCategorias.nodes[0];

    return <>{switchMenu(menuType)}</>;
};

export default Nav;
